import { useState } from 'react'
import { ReactComponent as LogoHover } from 'src/assets/logoHover.svg'
import { ReactComponent as LogoNormal } from 'src/assets/logo.svg'

const Logo = props => {
  const [isHover, setIsHover] = useState(false)
  return (
    <div
      onMouseOver={() => {
        setIsHover(true)
      }}
      onMouseOut={() => {
        setIsHover(false)
      }}
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
    >
      {isHover ? <LogoHover /> : <LogoNormal />}
    </div>
  )
}
export default Logo
