import { Image } from 'src/components/Thumbnail/Thumbnail.styled'

const koreanToEnglishMap = {
  나이트로드: 'nightLord',
  나이트워커: 'nightWalker',
  다크나이트: 'darkKnight',
  데몬슬레이어: 'demonSlayer',
  데몬어벤져: 'demonAvenger',
  듀얼블레이더: 'dualBlader',
  라라: 'lala',
  루미너스: 'ruminus',
  메르세데스: 'mercedes',
  메카닉: 'mechanic',
  미하일: 'mihael',
  바이퍼: 'viper',
  배틀메이지: 'battleMage',
  보우마스터: 'bowMaster',
  블래스터: 'blaster',
  비숍: 'bishop',
  소울마스터: 'soulMaster',
  스트라이커: 'striker',
  신궁: 'bowGod',
  아델: 'adele',
  아란: 'aran',
  아크: 'ark',
  '아크메이지(불,독)': 'flameArkMage',
  '아크메이지(썬,콜)': 'thunderArkMage',
  시프: 'assassin',
  시프마스터: 'assassin',
  섀도어: 'assassin',
  에반: 'evan',
  엔젤릭버스터: 'angelBuster',
  와일드헌터: 'wildHunter',
  윈드브레이커: 'windBreaker',
  은월: 'silverMoon',
  일리움: 'illium',
  제논: 'zenon',
  제로: 'zero',
  카데나: 'kadena',
  카이저: 'kaiser',
  카인: 'kain',
  칼리: 'kali',
  캐논슈터: 'canonShooter',
  캐논마스터: 'canonShooter',
  캐논블래스터: 'canonShooter',
  캡틴: 'captain',
  키네시스: 'kinesis',
  팔라딘: 'paladin',
  패스파인더: 'pathFinder',
  팬텀: 'phantom',
  플레임위자드: 'flameWizard',
  호영: 'hoYoung',
  히어로: 'hero',
  검사: 'hero',
  파이터: 'hero',
  크루세이더: 'hero',
  페이지: 'paladin',
  나이트: 'paladin',
  스피어맨: 'darkKnight',
  버서커: 'darkKnight',
  매지션: 'flameArkMage',
  '위자드(불,독)': 'flameArkMage',
  '메이지(불,독)': 'flameArkMage',
  '위자드(썬,콜)': 'thunderArkMage',
  '메이지(썬,콜)': 'thunderArkMage',
  클레릭: 'bishop',
  프리스트: 'bishop',
  아처: 'bowMaster',
  헌터: 'bowMaster',
  레인저: 'bowMaster',
  사수: 'bowGod',
  저격수: 'bowGod',
  로그: 'nightLord',
  어쌔신: 'nightLord',
  허밋: 'nightLord',
  듀어러: 'dualBlader',
  듀얼마스터: 'dualBlader',
  세미듀어러: 'dualBlader',
  슬래셔: 'dualBlader',
  해적: 'viper',
  인파이터: 'viper',
  버커니어: 'viper',
  건슬링거: 'captain',
  발키리: 'captain',
}
const Thumbnail = props => {
  const imageName = koreanToEnglishMap[props.job] || 'unknown'
  const imageSrc = `${process.env.PUBLIC_URL}/images/illust/${imageName}.png`

  return (
    <div>
      <Image src={imageSrc} alt={props.job} />
    </div>
  )
}
export default Thumbnail
