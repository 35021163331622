const FontSize = {
  mainTitle: '38px',
  bigTitle: '32px',
  title: '28px',
  title2: '22px',
  title3: '18px',
  body: '16px',
  footNote: '14px',
  caption: '12px',
}

export default FontSize
